<template>
<div>
   <v-overlay :value="overlay"></v-overlay>
  <!-- Loading.................................. -->
  <div class="text-center">
    <v-dialog
      v-model="loader"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
  <!-- end Loading-->
   <NavBar />
<div class=" bg-white md:bg-secondary flex flex-col min-h-screen">
    <div class="pt-4">
       <Success :successDialog="success_code"  @close_dialog="close_application_dialog"/>
     
    </div>

    <div
      class="
        flex-auto
        md:my-8
        mx-auto
        bg-white
        md:w-4/12
        rounded
        shadow
        py-5
        px-10
      "
    >
      <div class="text-center pb-3">
        <v-progress-linear
          v-model="progress"
          color="teal"
          height="5"
        ></v-progress-linear>
      </div>
      <!-- Step 0 -->
      <div v-if="step == 0">
        <h3 class="font-semibold text-center text-xl text-black">
          Become A Tutor
        </h3>
        <img src="@/assets/6162.png" alt="" />
        <p class="">
          We are looking for passionate and committed individuals who want to
          become a tutor! You’ll need to have excelled academically and be
          currently studying or have completed tertiary studies, to join our
          team of tutors. <b>We can’t wait to hear from you!</b>
        </p>
        <div class="flex justify-end py-2">
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="addStep"
            >Start</a
          >
        </div>
      </div>

      <!-- Step 1 -->
      <div v-else-if="step == 1">
        <h3 class="font-semibold text-center text-xl text-black">
          Do you have experience doing lessons online?
        </h3>
        <div class="">
          <a
            v-for="option in form_wizard.step_1"
            :key="option.text"
            class="
              font-mono
              text-blue-600
              py-4
              px-5
              block
              border
              rounded
              my-3
              hover:bg-yellow-fade
              shadow
            "
            :class="option.classes"
            @click="experience(option)"
            >{{ option.text }}</a
          >
        </div>
        <div class="flex justify-between py-2">
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="reduceStep"
            >Back</a
          >
          <a
            v-if="formData.experience != ''"
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="addStep"
            >Next</a
          >
        </div>
      </div>

      <!-- Step 2 -->
      <div v-else-if="step == 2" class="">
        <h3 class="font-semibold text-center text-xl text-black">
          Where do you leave?
        </h3>

        <form>
          <v-select
            v-model="formData.region"
            :items="regions"
            :error-messages="regionErrors"
            label="Region"
            required
            @change="regionchange"
            @blur="$v.formData.region.$touch()"
          ></v-select>
          <v-select
            v-if="formData.region == 'Southern Region'"
            v-model="formData.district"
            value=""
            :items="southern"
            :error-messages="districtErrors"
            label="District"
            required
            @change="$v.formData.district.$touch()"
            @blur="$v.formData.district.$touch()"
          ></v-select>
          <v-select
            v-else-if="formData.region == 'Central Region'"
            v-model="formData.district"
            value=""
            :items="central"
            :error-messages="districtErrors"
            label="District"
            required
            @change="$v.formData.district.$touch()"
            @blur="$v.formData.district.$touch()"
          ></v-select>
          <v-select
            v-else-if="formData.region == 'Northern Region'"
            v-model="formData.district"
            :items="northern"
            :error-messages="districtErrors"
            label="District"
            required
            @change="$v.formData.district.$touch()"
            @blur="$v.formData.district.$touch()"
          ></v-select>
          
        </form>
        <div class="flex justify-between py-2">
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="reduceStep"
            >Back</a
          >
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="validateLocation"
            >Next</a
          >
        </div>
      </div>
      <!-- Step 3 -->
      <div v-else-if="step == 3">
        <h3 class="font-semibold text-center text-xl text-black">
          How do we contact you?
        </h3>
        <div class="w-full">
          <form class="px-8 pt-6 pb-8 mb-4">
            <v-text-field
              v-model="formData.firstName"
              :error-messages="fNameErrors"
              label="First Name"
              required
              @input="$v.formData.firstName.$touch()"
              @blur="$v.formData.firstName.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.lastName"
              :error-messages="lNameErrors"
              label="Last Name"
              required
              @input="$v.formData.lastName.$touch()"
              @blur="$v.formData.lastName.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.email"
              :error-messages="emailErrors"
              label="Email / Username"
              required
              @input="$v.formData.email.$touch()"
              @blur="$v.formData.email.$touch()"
            ></v-text-field>
            <v-text-field
              v-model="formData.phone"
              prefix="+265"
              :error-messages="phoneErrors"
              label="Phone number"
              required
              @input="$v.formData.phone.$touch()"
              @blur="$v.formData.phone.$touch()"
            ></v-text-field>
          </form>
          <div class="flex justify-between py-2">
            <a
              class="
                py-2
                px-5
                bg-yellow
                hover:bg-yellow-fade
                rounded
                shadow
                font-semibold
                text-lg
              "
              @click="reduceStep"
              >Back</a
            >
            <a
              class="
                py-2
                px-5
                bg-yellow
                hover:bg-yellow-fade
                rounded
                shadow
                font-semibold
                text-lg
              "
              @click="validateDetails"
              >Next</a
            >
          </div>
        </div>
      </div>

        <!-- New Step 5 University Students-->

        <div v-scroll-reveal.reset v-else-if="step == 4" class="">
          <h3 class="font-semibold text-center text-xl text-black">
            What are you interested in?
          </h3>

          <form>
            <v-select v-model="formData.level" :items="levels" :error-messages="levelErrors" label="Level" required
              @change="$v.formData.level.$touch()" @blur="$v.formData.level.$touch()"></v-select>


          
            
            <v-select v-if="formData.level== 'Primary'" v-model="formData.level_type" :items="primary_level_types"
              :error-messages="primaryTypeErrors" label="Select Curriculum" required
              @change="$v.formData.level_type.$touch()" @blur="$v.formData.level_type.$touch()"  multiple></v-select>

            <v-select v-else-if="formData.level== 'Secondary'" v-model="formData.level_type" :items="secondary_level_types"
              :error-messages="secondaryTypeErrors" label="Select Curriculum" required
              @change="$v.formData.level_type.$touch()" @blur="$v.formData.level_type.$touch()"  multiple></v-select>
              
            
            <v-select v-if="formData.level === 'Primary' " v-model="formData.subject" :items="primary_subjects" item-text="name"
              item-value="id"  label="Select Your Required Subjects" multiple>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ value.length - 1 }} others)
                </span>
              </template>
            </v-select>
            <v-select v-if="formData.level === 'Secondary' " v-model="formData.subject" :items="secondary_subjects" item-text="name"
              item-value="id"  label="Select Your Required Subjects" multiple>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ value.length - 1 }} others)
                </span>
              </template>
            </v-select>
            <v-select v-if="formData.level === 'University' " v-model="formData.subject" :items="university_subjects" item-text="name"
              item-value="id"  label="Select Your Required Subjects" multiple>
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.name }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text text-caption">
                  (+{{ value.length - 1 }} others)
                </span>
              </template>
            </v-select>
            <div class="pt-5">
            <v-textarea v-if="formData.level != null" :error-messages="otherSubjectErrors"  @input="$v.formData.otherSubjects.$touch()" @blur="$v.formData.otherSubjects.$touch()" v-model="formData.otherSubjects" outlined name="input-7-4" label="Other subjects"
              value=""></v-textarea>
          </div>
          </form>
          <div class="flex justify-between py-2">
            <a class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            " @click="reduceStep">Back</a>
            <a class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            " @click="validateSubjects">Next</a>
          </div>
        </div>
        <!--end of new universitz details-->

      <!-- Step 4 -->
      <div v-else-if="step == 5">
        <h3 class="font-semibold text-center text-xl text-black">
          What is your gender?
        </h3>
        <div>
          <a
            v-for="option in form_wizard.step_4"
            :key="option.text"
            class="
              font-mono
              py-4
              px-5
              block
              border
              rounded
              my-3
              hover:bg-yellow-fade
              shadow
            "
            :class="option.classes"
            @click="genderOption(option)"
            >{{ option.text }}</a
          >
        </div>
        <div class="flex justify-between py-2">
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="reduceStep"
            >Back</a
          >
          <a
            v-if="formData.gender != ''"
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="addStep"
            >Next</a
          >
        </div>
      </div>

      <!-- Step 5 -->
      <div v-else-if="step == 6">
        <h3 class="font-semibold text-center text-xl text-black">
          Is there anything else you would like to add?
        </h3>
        <div class="py-5">
          <v-textarea
            v-model="formData.message"
            outlined
            name="input-7-4"
            label="Any additional information"
            value=""
          ></v-textarea>
          <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div>
                  I agree to being contacted regarding the service I'm enquiring about.
                  <p v-if="checkError==true"  class="text-red" >You need to agree before you submit</p>
                </div>
              </template>
            </v-checkbox>
        </div>
        <div class="flex justify-between py-2">
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="reduceStep"
            >Back</a
          >
          <a
            class="
              py-2
              px-5
              bg-yellow
              hover:bg-yellow-fade
              rounded
              shadow
              font-semibold
              text-lg
            "
            @click="submit"
            >Finish</a
          >
        </div>
      </div>
    </div>
    <div>
      <Footer />
    </div>
  </div>
</div>
  
</template>
<script>
import NavBar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

import DataService from "@/authentication/data.service";
import Success from "@/components/TutorSuccess.vue";

import { validationMixin } from "vuelidate";
import { required, maxLength, email, integer, minLength  } from "vuelidate/lib/validators";



export default {
  mixins: [validationMixin],
  components: {
    NavBar,
    Footer,
    Success,
  },
  validations: {
    formData: {
      location: { required, maxLength: maxLength(15) },
      email: { required, email },
      district: { required },
      region: { required },
      firstName: { required, maxLength: maxLength(50) },
      lastName: { required ,maxLength: maxLength(50)},
      subject: { required },
       phone: { required, integer, minLength: minLength(9), maxLength: maxLength(9) },

      level: { required },
      level_type: { required },
      university_real_name: { required },
      university_name: { required },
      otherSubjects:{ maxLength: maxLength(300) },

    },
  },

  data() {
    return {
       checkError:false,
       checkbox: false,
      interval: {},
      success_code:false,
     loader:false,
          overlay : false,
      progress: 0,
      universities: ["Malawi University of Science and Technology (MUST)",
        "Mzuzu University (MZUNI)",
        "Kamuzu University of Health Sciences (KUHeS)",
        "Malawi University of Business and Applied Sciences",
        "Lilongwe University of Agriculture and Natural Resources (LUANAR)",
        "University of Malawi (UNIMA)",
        "Malawi College of Accountancy (MCA)",
        "Malawi Institute of Management (MIM)",
        "The Catholic University of Malawi (CUNIMA)",
        "DMI St John the Baptist University",
        "Nkhoma University (NKHUNI)",
        "Malawi Assemblies of God University (MAGU)",
        "Daeyang University",
        "Malawi Adventist University (MAU)",
        "Pentecostal Life University (PLU)",
        "Management College of Southern Africa (MANCOSA)",
        "African Bible College (ABC)",
        "University of Livingstonia",
        "Exploits University (EU)",
        "University of Lilongwe (UNILIL)",
        "Millennium University (MU)",
        "Other",
      ],

      primary_subjects: [],
      secondary_subjects: [],
      university_subjects: [],

      levels: ["Primary", "Secondary", "University"],
      secondary_level_types:["Local", "Cambridge","Edexcel", "IB"],
      primary_level_types:["Local", "International"],
      //Form data
      formData: {
        experience: "",

        level:null,
        university_name:"",
        level_type:"",
        university_real_name:"",
        subject:null,
        otherSubjects:"",

        region: null,
        district: "",
        location: null,
        firstName: null,
        lastName: null,
        email: null,
        gender: "",
        message: "",
        phone: "",
      },

      //end of form data

     
      //end of Failure and success codes
      regions: ["Central Region", "Southern Region", "Northern Region"],
      northern: [
        "Chitipa",
        "Karonga",
        "Likoma",
        "Mzimba",
        "Nkhata Bay",
        "Rumphi",
      ],
      central: [
        "Dedza",
        "Dowa",
        "Kasungu",
        "Lilongwe",
        "Nkhotakota",
        "Ntcheu",
        "Ntchisi",
        "Salima",
      ],
      southern: [
        "Balaka",
        "Blantyre",
        "Chikwawa",
        "Chiradzulu",
        "Machinga",
        "Mangochi",
        "Mulanje",
        "Mwanza",
        "Neno",
        "Nsanje",
        "Phalombe",
        "Thyolo",
        "Zomba",
      ],
      subjects: [
        "Accounting",
        "Economics",
        "Maths",
        "Geography",
        "History",
        "English",
        "Physical Science",
        "Information Technology",
      ],
      value: [],
      step: 0,
      active_option: {
        "border-primary": true,
        "bg-primary": true,
        "bg-opacity-25": true,
      },
      normal_option: {
        "border-primary": false,
        "bg-primary": false,
        "bg-opacity-25": false,
      },

      form_wizard: {
        step_1_active: -1,
        step_1: [
          {
            id: 0,
            text: "Yes I Do.",
            active: false,
            classes: {
              "border-primary": false,
              "bg-primary": false,
              "bg-opacity-25": false,
            },
          },
          {
            id: 1,
            text: "No I Dont.",
            active: false,
            classes: {
              "border-primary": false,
              "bg-primary": false,
              "bg-opacity-25": false,
            },
          },
        ],
        step_4_active: -1,
        step_4: [
          {
            id: 0,
            text: "Male",
            active: false,
            classes: {
              "border-primary": false,
              "bg-primary": false,
              "bg-opacity-25": false,
            },
          },
          {
            id: 1,
            text: "Female",
            active: false,
            classes: {
              "border-primary": false,
              "bg-primary": false,
              "bg-opacity-25": false,
            },
          },
        ],
      },
    };
  },

   mounted() {
    DataService.getSubjects().then(
      (response) => {
        const subjects = response.data;
        const primary = subjects.filter(subject => subject.level == "Primary");
         const secondary = subjects.filter(subject => subject.level == "Secondary");
         const university = subjects.filter(subject => subject.level == "University");
        this.university_subjects = university
        this.primary_subjects = primary
        this.secondary_subjects = secondary
       
        console.log(this.primary_subjects)
        console.log(this.secondary_subjects)
        console.log(this.university_subjects)


      },
      (error) => {
        this.content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        console.log("error: ", this.content);
      }
    );
  },

  methods: {
    close_application_dialog(status){
      this.success_code = status
      this.formData={
        experience: "",
        region: null,
        district: "",
        location: null,
        firstName: null,
        lastName: null,
        email: null,
        gender: "",
        message: "",
        phone: "",
      },
      this.$router.push({ path: '/' })
    },
    sendApplication(user_id) {
      //if(this.formData.message===''){
      //  this.formData.message = "none"
      //}
      let applicationData = {
        user: user_id,
        experience: this.formData.experience,
        message: this.formData.message,
        level:this.formData.level,
        university_name:this.formData.university_real_name,
        curriculum:this.formData.level_type.toString(),
        other_subjects:this.formData.otherSubjects
      };

       const applicationData2 = {subject: this.formData.subject,}

      if(this.formData.subject){
        applicationData = Object.assign(applicationData,applicationData2);
      }

      console.log("application: ", applicationData);
      DataService.createTutorAplication(applicationData).then(
        (response) => {
          console.log(response.data);
          this.loader=false
          this.overlay = false
          this.success_code = true
          
        },
        (error) => {
          const content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log("error: ", content);
          this.loader = false
          this.overlay = false
        }
      );
    },
    createTutor(data) {
      DataService.createProfile(data).then(
        (response) => {
          console.log(response.data);
          this.sendApplication(response.data.user.id);
        },
        (error) => {
          const content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          console.log("error: ", content);
          this.loader = false
          this.overlay = false
        }
      );
    },
    addStep() {
      this.step += 1;
      this.progress += 10;
    },
    reduceStep() {
      this.step -= 1;
      this.progress -= 10;
    },
    regionchange() {
      this.$v.formData.region.$touch();
      this.formData.district = "";
    },
    submit() {
       if(this.checkbox == true){
        this.checkError = false
        this.loader = true
      this.overlay = true
      const data = {
        user: {
          username: this.formData.email,
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
          email: this.formData.email,
        },
        gender: this.formData.gender,
        contact_number: this.formData.phone,
        region: this.formData.region,
        district: this.formData.district,
        user_type: "Tutor",
      };
      //create student
      this.createTutor(data);
        }else{
           this.checkError = true
        }
      
    },
    //selection for gender
    experience(selection) {
      this.formData["experience"] = selection.text;
      const active_step = "step_1_active";
      const data = "step_1";
      this.options(selection, active_step, data);
    },

    //selection for gender
    genderOption(selection) {
      this.formData.gender = selection.text;
      const active_step = "step_4_active";
      const data = "step_4";
      this.options(selection, active_step, data);
    },

    //selection for message
    messageOption(selection) {
      this.formData.message = selection.text;
      this.options(selection);
    },
    options(option, active_step, data) {
      //Making a selection
      const id = this.form_wizard[active_step];
      console.log("here", this.form_wizard[data][option.id].classes);
      if (id != -1) {
        console.log("here", this.form_wizard[data]);
        this.form_wizard[data][id].classes = this.normal_option;
        this.form_wizard[data][option.id].classes = this.active_option;
        this.form_wizard[active_step] = option.id;
      } else {
        this.form_wizard[data][option.id].classes = this.active_option;
        this.form_wizard[active_step] = option.id;
      }
      //console.log(this.form_wizard.step_1_active)
      this.step += 1;
      this.progress += 10;
    },
    validateLocation() {
      this.$v.$touch();
      if (
        
        !this.$v.formData.district.$invalid &&
        !this.$v.formData.region.$invalid
      ) {
        this.step += 1;
        this.$v.$reset();
        this.progress += 10;
      }
    },

 
    validateDetails() {
      this.$v.$touch();
      if (
        !this.$v.formData.firstName.$invalid &&
        !this.$v.formData.lastName.$invalid &&
        !this.$v.formData.email.$invalid &&
        !this.$v.formData.phone.$invalid
      ) {
        this.step += 1;
        this.$v.$reset();
        this.progress += 10;
      }
    },
     validateSubjects() {

      this.$v.$touch();

      if (this.formData.level == "University") {


          if (
           
            
            !this.$v.formData.level.$invalid) {

            this.step += 1;
            this.$v.$reset();
            this.progress += 10;
           
          }

        



      }else{
         if (
            !this.$v.formData.level_type.$invalid&&
            !this.$v.formData.level.$invalid) {

            this.step += 1;
            this.$v.$reset();
            this.progress += 10;
           
          }
      }
    },
  },
  computed: {
     levelErrors() {
      const errors = [];
      if (!this.$v.formData.level.$dirty) return errors;
      !this.$v.formData.level.required && errors.push("Item is required");
      return errors;
    },
    uniNameErrors() {
      const errors = [];
      if (!this.$v.formData.university_name.$dirty) return errors;
      !this.$v.formData.university_name.required && errors.push("Item is required");
      return errors;
    },
      secondaryTypeErrors() {
      const errors = [];
      if (!this.$v.formData.level_type.$dirty) return errors;
      !this.$v.formData.level_type.required && errors.push("Item is required");
      return errors;
    },
     primaryTypeErrors() {
      const errors = [];
      if (!this.$v.formData.level_type.$dirty) return errors;
      !this.$v.formData.level_type.required && errors.push("Item is required");
      return errors;
    },
    uniRealNameErrors() {
      const errors = [];
      if (!this.$v.formData.university_real_name.$dirty) return errors;
      !this.$v.formData.university_real_name.required && errors.push("Item is required");
      return errors;
    },
    otherSubjectErrors() {
      const errors = [];
      if (!this.$v.formData.otherSubjects.$dirty) return errors;
      !this.$v.formData.otherSubjects.maxLength &&
        errors.push("Text must be at most 300 characters long");
      return errors;
    },
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push("You must agree to continue!");
      return errors;
    },
    districtErrors() {
      const errors = [];
      if (!this.$v.formData.district.$dirty) return errors;
      !this.$v.formData.district.required && errors.push("Item is required");
      return errors;
    },
    subjectErrors() {
      const errors = [];
      if (!this.$v.formData.subject.$dirty) return errors;
      !this.$v.formData.subject.required && errors.push("Item is required");
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.formData.region.$dirty) return errors;
      !this.$v.formData.region.required && errors.push("Item is required");
      return errors;
    },
    fNameErrors() {
      const errors = [];
      if (!this.$v.formData.firstName.$dirty) return errors;
      !this.$v.formData.firstName.required && errors.push("Item is required");
      !this.$v.formData.firstName.maxLength &&
        errors.push("Name must be at most 50 characters long");
      return errors;
    },
    lNameErrors() {
      const errors = [];
      if (!this.$v.formData.lastName.$dirty) return errors;
      !this.$v.formData.lastName.maxLength &&
        errors.push("Name must be at most 50 characters long");
      !this.$v.formData.lastName.required && errors.push("Item is required");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.formData.location.$dirty) return errors;
      !this.$v.formData.location.maxLength &&
        errors.push("Name must be at most 15 characters long");
      !this.$v.formData.location.required && errors.push("Name is required.");
      return errors;
    },
     phoneErrors() {
      const errors = [];
      if (!this.$v.formData.phone.$dirty) return errors;
      !this.$v.formData.phone.required &&
        errors.push("Phone number is required.");
      !this.$v.formData.phone.integer &&
        errors.push("Must contain only numbers");
      !this.$v.formData.phone.minLength &&
        errors.push("Phone input must be 9 digits long e.g +265-XXX-XXX-XXX");
      !this.$v.formData.phone.maxLength &&
        errors.push("Phone input must be 9 digits long e.g +265-XXX-XXX-XXX");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.formData.email.$dirty) return errors;
      !this.$v.formData.email.email && errors.push("Must be valid e-mail");
      !this.$v.formData.email.required && errors.push("E-mail is required");
      return errors;
    },
  },
};
</script>
<style >

</style>